<template id='group-button-template'>
    <div class='input-group'>
        <b-form-select v-model='selectedItem' @change='manualChange' size="sm">
            <option v-for='item in items' :key='item.id' :value='item[returns]'>
                {{ $t("common.bill_size",{obj:item.name})}}
            </option>
        </b-form-select>
        <div class='input-group-append'>
            <button class='btn btn-success btn-sm' @click='addItem'><i class='fa fa-file-pdf-o'></i></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GroupButton',
    props: {
        value: {
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        returns: {
            type: String,
            default: 'id'
        },
        firstLabel: {
            type: String,
            default: 'Please select'
        },
        firstDisabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            addedItem: this.value || 0,
            selectedItem: this.value || 0
        }
    },
    computed: {
        columns() {
            if(!this.items.length) return []
            return Object.keys(this.items[0])
        }
    },
    methods: {
        addItem() {
            this.addedItem = this.selectedItem

            if(this.addedItem == 0 && this.firstDisabled) return

            this.$emit('input', this.addedItem)
            this.$emit('selected')
        },
        manualChange() {
            this.addedItem = this.selectedItem
            this.$emit('input', this.addedItem)
        }
    }
}
</script>